@use "../../../assets//styles/abstracts/mixins" as *;

.small_container {
  width: 80%;
  margin: 9rem auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  @include mq(400px, max) {
    width: 100%;
  }

  .extra-header {
    margin-top: -6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .info-header {
      display: flex;
      align-items: center;
      gap: 10px;
      .title {
        width: max-content;
        color: var(--white, #fff);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include mq(900px, max) {
          font-size: 29px;
          margin-left: 1rem;
        }

        .hashtag {
          color: var(--primary, #0ea4f8);
        }
      }

      .line {
        border: 0.5px solid var(--primary, #0ea4f8);
        width: 25rem;

        @include mq(600px, max) {
          display: none;
        }
      }
    }
    .subheader {
      color: #fff;
      font-family: Fira Code;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 1rem;
    }
  }

  .small_container_items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    .smallProjectItem {
      max-width: 350px;
      height: max-content;
      border: 1px solid var(--gray, #abb2bf);

      .title {
        padding: 10px;
        color: var(--gray, #abb2bf);
        font-family: Fira Code;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-bottom: 1px solid var(--gray, #abb2bf);
      }
      .body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        h3 {
          color: var(--white, #fff);
          font-family: Fira Code;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        p {
          color: var(--gray, #abb2bf);
          font-family: Fira Code;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .button {
          border: 1px solid var(--primary, #0ea4f8);
          padding: 10px;
          width: max-content;
        }
      }
    }
  }

  .shape {
    position: absolute;
    height: 12rem;
    width: 12rem;
    left: -13rem;
    top: 10rem;
  }
}
