@use "../../../assets//styles/abstracts/mixins" as *;

.complete_container {
  width: 80%;
  margin: 9rem auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  @include mq(400px, max) {
    width: 100%;
  }

  .extra-header {
    margin-top: -6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .info-header {
      display: flex;
      align-items: center;
      gap: 10px;
      .title {
        width: max-content;
        color: var(--white, #fff);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include mq(900px, max) {
          font-size: 29px;
          margin-left: 1rem;
        }

        .hashtag {
          color: var(--primary, #0ea4f8);
        }
      }

      .line {
        border: 0.5px solid var(--primary, #0ea4f8);
        width: 25rem;

        @include mq(600px, max) {
          display: none;
        }
      }
    }
    .subheader {
      color: #fff;
      font-family: Fira Code;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 1rem;
    }
  }
  .second-header {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .info-header {
      display: flex;
      align-items: center;
      gap: 10px;
      .title {
        width: max-content;
        color: var(--white, #fff);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include mq(900px, max) {
          font-size: 29px;
          margin-left: 1rem;
        }

        .hashtag {
          color: var(--primary, #0ea4f8);
        }
      }

      .line {
        border: 0.5px solid var(--primary, #0ea4f8);
        width: 25rem;

        @include mq(600px, max) {
          display: none;
        }
      }
    }
    .subheader {
      color: #fff;
      font-family: Fira Code;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 1rem;
    }
  }
  .complete_projects_container {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .points {
    position: absolute;
    left: -9rem;
    top: 18rem;
    height: 5rem;
  }
  .square {
    position: absolute;
    right: -19rem;
    top: 50rem;
    height: 15rem;

  }
}
