@use "../../../assets/styles/abstracts/variables" as *;
@use "../../../assets//styles/abstracts/mixins" as *;

.projects_container {
  margin-top: 12rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .points {
    position: absolute;
    left: 0;
    top: 70px;
    @include mq(1100px, max) {
      display: none;
    }
  }
  .square {
    position: absolute;
    right: 0;
    bottom: 40px;
    border: 2px solid #abb2bf;
    width: 5rem;
    height: 10rem;
    @include mq(1100px, max) {
      display: none;
    }
  }

  .header_part {
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-between;

    .right_part {
      display: flex;
      align-items: center;
      gap: 2rem;
      width: max-content;
      .title {
        color: var(--white, #fff);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        .hashtag {
          color: var(--primary, #0ea4f8);
        }
        @include mq(900px, max) {
          font-size: 29px;
        }
      }
      .line {
        border: 0.5px solid var(--primary, #0ea4f8);
        width: 40rem;

        @include mq(900px, max) {
          width: 20rem;
        }
        @include mq(700px, max) {
          width: 15rem;
        }
        @include mq(500px, max) {
          display: none;
        }
      }
    }

    .details {
      color: var(--white, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @include mq(900px, max) {
        font-size: 14px;
      }
    }
  }
  .project_items {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include mq(900px, max) {
      grid-template-columns: repeat(2, 1fr);

      @include mq(600px, max) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
.project_container {
  border: 2px solid var(--gray, #abb2bf);
  height: 490px;
  width: 330px;
  .img_container {
    object-fit: cover;
    height: 200px;
    overflow: hidden;
    border-bottom: 2px solid var(--gray, #abb2bf);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .technologies {
    padding: 7px;
    max-height: max-content;
    border-bottom: 2px solid var(--gray, #abb2bf);
    color: var(--gray, #abb2bf);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px 10px;
    .title {
      color: var(--white, #fff);
      font-family: Fira Code;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .description {
      color: var(--gray, #abb2bf);
      font-family: Fira Code;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .status_container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .status {
      padding: 7px;
      width: max-content;
      border: 2px solid var(--primary, #0ea4f8);
      color: var(--white, #fff);
      font-family: Fira Code;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .location {
      padding: 7px;
      width: max-content;
      border: 2px solid var(--primary, #abb2bf);
      color: var(--white, #fff);
      font-family: Fira Code;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      align-self: flex-end;
    }
  }
}
