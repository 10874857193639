@use "../../assets//styles/abstracts/mixins" as *;

.notFound_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    margin-left: -10rem;
  }
  p {
    margin-left: 3rem;
    text-align: center;
    span {
      color: red;
      font-weight: bold;
    }
  }

  @include mq(700px, max) {
    img {
      width: 80%;
      margin-left: 0;
    }
    p {
      width: 80%;
    }
  }
}
