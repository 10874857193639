@use "../../../assets//styles/abstracts/mixins" as *;

.funfacts_container {
  width: 80%;
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @include mq(400px, max) {
    width: 100%;
    padding: 10px;
  }

  .extra-header {
    margin-top: -2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .info-header {
      display: flex;
      align-items: center;
      gap: 10px;
      .title {
        width: max-content;
        color: var(--white, #fff);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include mq(900px, max) {
          font-size: 29px;
          margin-left: 1rem;
        }

        .hashtag {
          color: var(--primary, #0ea4f8);
        }
      }

      .line {
        border: 0.5px solid var(--primary, #0ea4f8);
        width: 25rem;
        @include mq(600px, max) {
          width: 10rem;
          margin-left: 1rem;
        }
        @include mq(550px, max) {
          display: none;
        }
      }
    }
    .subheader {
      color: #fff;
      font-family: Fira Code;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 1rem;
    }
  }
  .funfacts_container_info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftPart {
      width: 70%;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      @include mq(1000px, max) {
        width: 100%;
      }
      .quote {
        color: var(--gray, #abb2bf);
        font-family: Fira Code;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: max-content;
        border: 1px solid var(--gray, #abb2bf);
        padding: 10px;
        span {
          color: white;
        }
      }
    }
    .rightPart {
      @include mq(1000px, max) {
        display: none;
      }
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 30%;
      justify-content: center;
      align-items: center;

      img {
        height: 80px;
        width: 80px;
      }
    }
  }
}
