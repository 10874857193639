/* StarryBackground.css */
.starry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  animation: stars 100s linear infinite;
  background-color: transparent;
}

@keyframes stars {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100vh);
  }
}

.starry-background::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  animation: twinkle 3s infinite alternate;
}

@keyframes twinkle {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

/* StarField.css */
.star-field {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #fff;
  border-radius: 50%;
  animation: twinkle linear infinite;
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
